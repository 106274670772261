import React from 'react';

import iconSet from './selection.json';
import IcomoonReact from 'icomoon-react';

export default function IconComparator({icon, color, size, ...rest}: any) {
  return (
    <IcomoonReact
      iconSet={iconSet}
      icon={icon}
      size={size}
      color={color}
      {...rest}
    />
  );
}
